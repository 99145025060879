import React from 'react'

function TechstackCarousel() {
    return(
        <section id="techstackCarousel" name="techstackCarousel">
            <div className="container-fluid-small text-center">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6"><img className="img-fluid" src='/assets/images/techstack/adobe_spp_bronze.png' alt="Adobe Solution Partner" /></div>
                    <div className="col-lg-3 col-md-6 col-sm-6"><img className="img-fluid" src='/assets/images/techstack/Adobe_AEC.png' alt="Adobe Experience Cloud" /></div>
                    <div className="col-lg-3 col-md-6 col-sm-6"><img className="img-fluid" src='/assets/images/techstack/Adobe_AEP.png' alt="Adobe Experience Platform" /></div>
                    <div className="col-lg-3 col-md-6 col-sm-6"><img className="img-fluid" src='/assets/images/techstack/firebase.png' alt="Firebase" /></div>
                    <div className="col-lg-3 col-md-6 col-sm-6"><img className="img-fluid" src='/assets/images/techstack/analytics_360.png' alt="Google Analytics 360" /></div>
                    <div className="col-lg-3 col-md-6 col-sm-6"><img className="img-fluid" src='/assets/images/techstack/tableau.png' alt="Tableau" /></div>
                    <div className="col-lg-3 col-md-6 col-sm-6"><img className="img-fluid" src='/assets/images/techstack/powerbi.png' alt="Power BI" /></div>
                    <div className="col-lg-3 col-md-6 col-sm-6"><img className="img-fluid" src='/assets/images/techstack/onetrust.png' alt="OneTrust" /></div>
                </div>
            </div>
        </section>
    )

}

export default TechstackCarousel