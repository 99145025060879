import { useState, useEffect } from 'react'

const useForm = (callback, validate) => {

  const [values, setValues] = useState({
    email: '',
    company: '',
    message: ''
  })
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    })
    // window.dataLayer.push({
    //     event: 'formHandleChange',
    //     formName: 'contactForm'
    // })
  }

  const handleSubmit = e => {
    e.preventDefault()

    setErrors(validate(values))
    setIsSubmitting(true)
  }

  useEffect(
    () => {
      if (Object.keys(errors).length === 0 && isSubmitting) {
          fetch('https://europe-west1-anlytics-web.cloudfunctions.net/sendMail', {
              method: 'POST',
              body: JSON.stringify(values),
              headers: {
                  'Content-Type': 'application/json'
              }
          }).then(res => res.json())
              .then(data => {
                  window.dataLayer.push({
                      event: 'submitForm',
                      formName: 'contactForm',
                      formData: values,
                      submitResponse: data
                  })
                  callback();
              })
              .catch(err => {
                  console.error("Error:", err)
                  window.dataLayer.push({
                      event: 'submitError',
                      formName: 'contactForm',
                      formData: values,
                      submitErrorDesc: err
                  })
              });
      }
    },
    [errors]
  )

  return { handleChange, handleSubmit, values, errors }

}

export default useForm
