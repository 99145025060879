import React from 'react'
import validate from './validateInfo'
import useForm from './useForm'
import {t, Trans} from "@lingui/macro";
import {i18n} from "@lingui/core";

const FormSignup = ({submitForm }) => {

  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  )

  return (
    <div className='form-content-right'>
      <form onSubmit={handleSubmit} className='form' noValidate>

        <div className='form-inputs'>
          <input
              className='form-input'
              type='text'
              name='company'
              placeholder={i18n._(t`form.placeholder.company`) + '* :'}
              value={values.company}
              onChange={handleChange}
          />
          {errors.company && <p>{errors.company}</p>}
        </div>

        <div className='form-inputs'>
          <input
            className='form-input'
            type='email'
            name='email'
            placeholder={i18n._(t`form.placeholder.email`) + '* :'}
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p>{errors.email}</p>}
        </div>

        <div className='form-inputs'>
          <textarea
            className='form-input'
            name='message'
            placeholder={i18n._(t`form.placeholder.message`) + '* :'}
            value={values.message}
            onChange={handleChange}
          />
          {errors.message && <p>{errors.message}</p>}
        </div>

        <button className='form-input-btn' type='submit'>
          <Trans>form.submit</Trans>
        </button>

      </form>

    </div>
  )

}

export default FormSignup
