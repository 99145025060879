import React, {useEffect} from 'react'
import {Switch, Route, useLocation, withRouter, BrowserRouter as Router} from 'react-router-dom'
import {AnimatePresence} from 'framer-motion';
import { Helmet } from "react-helmet";


import Home from './pages/Home'
import Contact from './pages/Contact'
import Page404 from './pages/404'
import Cookies from "./pages/Cookies";

import ScrollTop from './components/ScrollTop'


function App(params) {

  const location = useLocation()

  useEffect(() => { setTimeout(()=>{ window.scrollTo(0, 0) }, 500) }, [location])


  return (
    <div>
        <Helmet>
            <html lang={`${params.locale}`} />
            <link rel="alternate" hrefLang={params.locale === 'cs' ? 'en' : 'cs'} href={params.locale === 'cs' ? 'https://anlytics.io/en/' : 'https://anlytics.io'}/>
            <meta property="og:url" content={'https://anlytics.io' + window.location.pathname} />
        </Helmet>
        <ScrollTop />
        <AnimatePresence initial={true} exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
            <Route exact path='/'><Home /></Route>
            <Route exact path='/contact'><Contact /></Route>
            <Route exact path='/cookies'><Cookies /></Route>
            <Route ><Page404 /></Route>
          </Switch>
        </AnimatePresence>
    </div>
  )

}

const WithRouterApp = withRouter(App);
export default WithRouterApp
