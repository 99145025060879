import {t} from "@lingui/macro";
import {i18n} from "@lingui/core";

export default function validateInfo(values) {
  let errors = {}

  if (!values.email) {
    errors.email = i18n._(t`form.errors.email.empty`)
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = i18n._(t`form.errors.email.invalid`)
  }
  if (!values.company) {
    errors.company = i18n._(t`form.errors.company.empty`)
  }
  if (!values.message) {
    errors.message = i18n._(t`form.errors.message.empty`)
  }

  if (Object.keys(errors).length > 0) {
    window.dataLayer.push({
      event: "validateForm",
      formName: 'contactForm',
      formErrors: errors
    });
  }

  return errors
}
