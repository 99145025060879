import React, {useEffect, useRef} from 'react'
import {gsap} from 'gsap'
import {motion} from "framer-motion";
import { Trans } from '@lingui/macro'

import Header from '../components/Header'
import Footer from '../components/Footer'

import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


function Cookies(){

    // let titleAnim = useRef(null)
    // let subtitleAnim = useRef(null)
    // let tl = gsap.timeline()
    const LoadingTransition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

    // useEffect(() => {
    //
    //     tl.from(subtitleAnim, {
    //         y: 15,
    //         opacity: 0,
    //         delay: 1,
    //         duration: .5,
    //         ease: "power3.inOut"
    //         })
    //         .from(titleAnim, {
    //             y: 15,
    //             opacity: 0,
    //             duration: .5,
    //             ease: "power3.inOut"
    //         })
    //         .from(".scroll-down", {
    //             y: 15,
    //             opacity: 0,
    //             duration: .5,
    //             ease: "power3.inOut"
    //         });
    //
    // }, [titleAnim, subtitleAnim])

    return(
        <div>
            <motion.div 
                initial={{x:0}}
                animate={{x:'-100%'}}
                exit={{x:0}}
                transition={LoadingTransition}
                className="page-trans">
            </motion.div>
            <Header />
            <section id="singleNews" className="section">
                <div className="container-fluid-small">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 single-news-left">

                            <h3 className="big-title anim-bot"><Trans>cookies.title</Trans></h3>
                            <p>
                                <Trans>cookies.about</Trans>
                            </p>
                            <h7 className="anim-bot"><Trans>cookies.why.heading</Trans></h7>
                            <p>
                                <Trans>cookies.why.text1</Trans>
                            </p>
                            <p>
                                <Trans>cookies.why.text2</Trans> <a target="_blank" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"><Trans>cookies.ga.link</Trans></a>.
                            </p>
                            <h7 className="anim-bot"><Trans>cookies.delete.heading</Trans></h7>
                            <p>
                                <Trans>cookies.delete.text1</Trans>
                            </p>
                            <p>
                                <Trans>cookies.delete.text2</Trans>
                                <ul>
                                    <li>Google Chrome <a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=cs">https://support.google.com/chrome/answer/95647?hl=cs</a></li>
                                    <li>Mozilla Firefox <a target="_blank" href="https://support.mozilla.org/cs/kb/povoleni-zakazani-cookies">https://support.mozilla.org/cs/kb/povoleni-zakazani-cookies</a></li>
                                    <li>Microsoft Edge <a target="_blank" href="https://support.microsoft.com/cs-cz/help/4027947/microsoft-edge-delete-cookies">https://support.microsoft.com/cs-cz/help/4027947/microsoft-edge-delete-cookies</a></li>
                                    <li>Microsoft Internet Explorer <a target="_blank" href="http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies</a></li>
                                    <li>Apple Safari <a target="_blank" href="https://support.apple.com/cs-cz/guide/safari/sfri11471/mac">https://support.apple.com/cs-cz/guide/safari/sfri11471/mac</a></li>
                                </ul>
                            </p>
                            <p>
                                <Trans>cookies.contact</Trans> <a href="mailto:info@anlytics.io">info@anlytics.io</a>.
                            </p>

                        </div>
                        <div className="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-4 single-news-right sidebar">
                            <div className="widget">
                                <h5 className="sub-title"><Trans>cookies.related.title</Trans></h5>
                                <div className="recent-post">
                                    <h4><a href={process.env.PUBLIC_URL + '/file-download/_shared/anlytics_ochrana_osobnich_udaju_20210406.pdf'} target="_blank"><Trans>cookies.related.privacypolicy.link</Trans></a></h4>
                                    <p className="recent-date"><Trans>cookies.related.privacypolicy.date</Trans></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )

}

export default Cookies