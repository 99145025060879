import React from 'react'
import {Trans} from "@lingui/macro";

function Block404(){

    return(
        <section id="heroSection" className="simple-hero section page-404">
            <div className="container-fluid-small">
                <div className="row">

                    <div className="col-12">
                        <h6 className="sub-title"><Trans>404.hero.sub.title</Trans></h6>
                        <h1 className="big-title"><Trans>404.hero.big.title</Trans></h1>
                        <a href="/"><Trans>404.hero.link</Trans></a>
                    </div>

                </div>
            </div>
        </section>
    )

}

export default Block404