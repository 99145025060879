import React from 'react'
import ReactDOM from 'react-dom'
import WithRouterApp from './App'
import {I18nProvider} from "@lingui/react";
import {BrowserRouter as Router} from 'react-router-dom'

import './index.scss'
import {i18n} from "@lingui/core";
import {detect, fromNavigator, fromPath} from "@lingui/detect-locale"
import catalogCs from './locales/cs/messages.js'
import catalogEn from './locales/en/messages.js'

i18n.load({
    'en': catalogEn.messages,
    'cs': catalogCs.messages
})


let locale = detect(
    fromPath(0),
    // fromNavigator(),
    'cs' // default fallback
)

let basename = '';

if (locale === 'en') {
    i18n.activate('en')
    basename = 'en';
} else {
    i18n.activate('cs')
}

window.dataLayer.push({language: locale});


ReactDOM.render(
    <I18nProvider i18n={i18n}>
        <Router basename={basename}>
            {/*<Redirect to="/en" />*/}
            <WithRouterApp locale={locale} />
        </Router>
    </I18nProvider>,
  document.getElementById('root')
)