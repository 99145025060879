import React from 'react'
import {useLocation, Link} from 'react-router-dom'
import {t} from "@lingui/macro";

function Menu(props) {

    const location = useLocation();
    const menuLinks = [
        {
           name: props.i18n._(t`menu.home`),
           url: '/',
           active: false
        },
         {
            name: props.i18n._(t`menu.contact`),
            url: '/contact',
            active: false
         }
    ]
    const displayLinks = menuLinks.map((link, i) => <li key={i}><Link to={link.url} className={location.pathname === link.url ? 'active' : null}>{link.name}</Link></li>)

    return (
        <ul className="main-menu">
            {displayLinks}
        </ul>
    )

}

export default Menu