import React from 'react'

import Form from '../elements/Form/Form'
import {Trans} from "@lingui/macro";
import {i18n} from "@lingui/core";

function ContactBody() {

    const date = new Date().getFullYear()

    return(
        <section id="contactSection" className="section">
            <div className="container-fluid-small">
                <div className="row">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 contact-left">
                        <h2 className="big-title anim-bot"><Trans>contact.footer.title</Trans></h2>
                        <div className="row">

                            <div className="col-12 col-sm-6 anim-bot">
                                <h5 className="sub-title"><Trans>contact.footer.contact</Trans></h5>
                                <ul>
                                    <li>info@anlytics.io</li>
                                    <li>+420 777 900 401</li>
                                </ul>
                            </div>
        
                            <div className="col-12 col-sm-6 anim-bot">
                                <h5 className="sub-title"><Trans>footer.address</Trans></h5>
                                <ul>
                                    <li>Korunní 2569/108</li>
                                    <Trans id={"footer.address.citycountry"}>
                                        <li>Praha 10 - Vinohrady</li>
                                        <li>Česká republika</li>
                                    </Trans>
                                </ul>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 copyright">
                                <p><Trans id={"contact.footer.company.details"}>
                                    IČ: 093 21 993
                                    <br/>
                                    Spisová značka: C 333765 vedená u Městského soudu v Praze
                                </Trans>
                                    <br/><br/><br/>
                                    Copyright © {date} anlytics digital s.r.o.
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 contact-right anim-right">
                        <Form i18n={i18n} />
                    </div>

                </div>
            </div>
        </section>
    )

}

export default ContactBody