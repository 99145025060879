import React from 'react'
import Icofont from 'react-icofont'
import {Trans} from "@lingui/macro";

const FormSuccess = () => {

  return (
    <div className='succes-message'>
      <Icofont icon='check-circled' />
      <h1 className="big-title"><Trans>form.success.big.title</Trans></h1>
      <p className="sub-title">
          <Trans>form.success.sub.title</Trans>
      </p>
    </div>
  )
}

export default FormSuccess
