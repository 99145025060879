import React from 'react'
import {Link} from 'react-router-dom'
import {Trans} from "@lingui/macro";
import {Image} from "react-bootstrap";

function AboutText(){

    return(
        <section id="aboutStory" className="section">
            <div className="container-fluid-small">
                <div className="row">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-left anim-bot">
                        <h2 className="big-title anim-bot"><Trans id={"hp.about.title"}>Baví nás pracovat<br /> na velkých projektech.</Trans></h2>
                        {/*<Link to='/'>View projects</Link>*/}
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-right anim-right">
                        <div className="story">
                            <p className="big-p"><Trans>hp.about.text</Trans></p>
                        </div>
                    </div>

                    <div className="col-xl-3"></div>

                    <div className="col-xl-3"></div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 story-bottom anim-bot">
                        <ul>
                            <Trans id={"hp.about.services.col1"}>
                                <li>Adobe Experience Cloud</li>
                                <li>Google Analytics 360</li>
                                <li>Tag management</li>
                            </Trans>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 story-bottom anim-bot">
                        <ul>
                            <Trans id={"hp.about.services.col2"}>
                            <li>Solution design</li>
                            <li>Integrace, API, ETL, BigQuery</li>
                            <li>Vizualizace (Power BI, Tableau)</li>
                            </Trans>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
    
}

export default AboutText