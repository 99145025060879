import React from 'react'
import {Link} from 'react-router-dom'
import {t, Trans} from '@lingui/macro'
import {i18n} from "@lingui/core";
import {Redirect} from "react-router";

function Footer() {

    const date = new Date().getFullYear()

    const footerLinks = [
        {
            name: 'GitHub',
            url: 'https://www.github.com/anlytics/'
        }
    ]

    return(
        <footer id="footerSection">
            <div className="container-fluid-small">

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 footer-title anim-bot">
                        <h2 className="big-title"><Trans>footer.title</Trans></h2>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 footer-project anim-bot">
                        <Link to='/contact'><Trans>footer.CTA</Trans></Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-4 copyright">
                        <p>
                            Copyright © {date} anlytics digital s.r.o.<br />
                            <span className="cookie-notice"><Trans>footer.cookies</Trans> <a className="footer-cookies-notice" href={i18n.locale === 'cs' ? '/cookies' : '/en/cookies'}>cookies</a>.</span>
                        </p>
                        <div className="footer-menu">
                            <ul>
                                {footerLinks.map((item, i) => <li key={i}><a href={item.url}>{item.name}</a></li>)}
                                <li>
                                    <a href={i18n.locale === 'cs' ? '/en/' : '/'}><Trans>footer.language.switch</Trans></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-1 footer-offset"></div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 contact-box contact-mail">
                        <h5 className="sub-title"><Trans>footer.contact</Trans></h5>
                        <ul>
                            <li>info@anlytics.io</li>
                            <li>+420 777 900 401</li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 contact-box contact-address">
                        <h5 className="sub-title"><Trans>footer.address</Trans></h5>
                        <ul>
                            <li>Korunní 2569/108</li>
                            <Trans id={"footer.address.citycountry"}>
                                <li>Praha 10 - Vinohrady</li>
                                <li>Česká republika</li>
                            </Trans>
                        </ul>
                    </div>
                </div>

            </div>
        </footer>
    )

}

export default Footer