import React, {useRef, useEffect} from 'react'
import {gsap} from 'gsap'
import {motion} from "framer-motion";

import Hero from '../components/Hero'
import Header from '../components/Header'
import Footer from '../components/Footer'

import {ScrollTrigger} from 'gsap/ScrollTrigger';
import AboutText from "../components/AboutText";
import { Trans } from '@lingui/macro'
import TechstackCarousel from "../components/TechstackCarousel";

gsap.registerPlugin(ScrollTrigger);

function Home() {

    let titleAnim = useRef(null)
    let subtitleAnim = useRef(null)
    let tl = gsap.timeline()
    const LoadingTransition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

    useEffect(() => {

        tl.from(subtitleAnim, {
            y: 15,
            opacity: 0,
            delay: 1,
            duration: .5,
            ease: "power3.inOut"
            })
            .from(titleAnim, {
                y: 15,
                opacity: 0,
                duration: .5,
                ease: "power3.inOut"
            })
            .from(".scroll-down", {
                y: 15,
                opacity: 0,
                duration: .5,
                ease: "power3.inOut"
            });

    }, [titleAnim, subtitleAnim])

    return(
        <div>
            <motion.div 
                initial={{x:0}}
                animate={{x:'-100%'}}
                exit={{x:0}}
                transition={LoadingTransition}
                className="page-trans">
            </motion.div>
            <Header/>
            <Hero>
                <h6 ref={el => subtitleAnim = el} className="sub-title"><Trans>hero.sub.title</Trans></h6>
                <h1 ref={el => titleAnim = el} className="big-title"><Trans id={"hero.big.title"}>big<br/>title</Trans></h1>
            </ Hero>
            <AboutText />
            <TechstackCarousel />
            <Footer />
        </div>
    )

}

export default Home